import React, { FC, PropsWithChildren, useEffect, useRef, useState } from "react";
import { Box, Collapse, Grid2, Link } from "@mui/material";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { useTheme } from "@mui/material/styles";

const maxHeight = 465;

export const ShowMore: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslationWrapper();
  const [showMore, setShowMore] = useState(window.innerHeight < 365);
  const theme = useTheme();
  const childRef = useRef<HTMLDivElement>();
  const [childHeight, setChildHeight] = useState(0);
  useEffect(() => {
    //We need to wait for 300ms to load the image (dynamic content)
    setTimeout(() => {
      if (childRef.current) {
        setChildHeight(childRef.current.clientHeight);
      }
    }, 300);
  }, []);

  return childHeight > maxHeight ? (
    <Box
      sx={{
        position: "relative"
      }}
    >
      <Collapse orientation="vertical" in={showMore} collapsedSize={maxHeight}>
        <Box ref={childRef} sx={{ height: showMore ? "auto" : maxHeight }}>
          {children}
        </Box>
      </Collapse>

      <Grid2
        container
        sx={{
          position: "relative",
          justifyContent: "center",
          alignItems: "end",
          height: showMore ? theme.spacing(12) : theme.spacing(18),
          mt: showMore ? 0 : -18,
          background: `linear-gradient(to bottom, transparent 0%, ${theme.palette.background.default} 90%)`
        }}
      >
        <Link
          sx={{ marginTop: theme.spacing(showMore ? 0 : 10), cursor: "pointer" }}
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? t("buttons__show_less") : t("buttons__show_more")}
        </Link>
      </Grid2>
    </Box>
  ) : (
    <Box ref={childRef}>{children}</Box>
  );
};
