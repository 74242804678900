import { generateURLSearchParams } from "src/components/table/hooks/use-search-filter-params";
import {
  FilterMapInterface,
  FilterObjectType,
  serializeIdOfFilter
} from "../../components/table/filters/table-filters";
import { ConversationStatus } from "../../graphql-messaging/generated/graphql";
import { FilterOperator } from "../../types/filters/filters";

export enum ConversationTabsValues {
  INBOX = "INBOX",
  ASSIGNED_TO_ME = "ASSIGNED_TO_ME",
  RESOLVED = "RESOLVED",
  ALL = "ALL",
  NONE = "NONE"
}

const statusInFilter = {
  name: "status",
  type: FilterObjectType.ADDITIONAL,
  operator: FilterOperator.In
};

const statusEqualFilter = {
  name: "status",
  type: FilterObjectType.ADDITIONAL,
  operator: FilterOperator.Equality
};

const assignedFilter = {
  name: "assignedTo",
  type: FilterObjectType.AUTOCOMPLETE,
  operator: FilterOperator.Equality
};

export const INBOX_FILTERS = {
  [serializeIdOfFilter(statusInFilter)]: {
    ...statusInFilter,
    value: [ConversationStatus.New, ConversationStatus.InProgress].join(",")
  }
};

const ASSIGNED_TO_ME_FILTERS = ({ mineActorId }: { mineActorId?: string }) => ({
  [serializeIdOfFilter(statusInFilter)]: {
    ...statusInFilter,
    value: [ConversationStatus.InProgress].join(",")
  },
  [serializeIdOfFilter(assignedFilter)]: {
    ...assignedFilter,
    value: mineActorId
  }
});
const RESOLVED_FILTERS = {
  [serializeIdOfFilter(statusEqualFilter)]: {
    ...statusEqualFilter,
    value: ConversationStatus.Resolved
  }
};

export const CONVERSATION_PREDEFINED_FILTER: {
  [key: string]: ({ mineActorId }: { mineActorId?: string }) => FilterMapInterface;
} = {
  [ConversationTabsValues.ALL]: () => ({}),
  [ConversationTabsValues.INBOX]: () => INBOX_FILTERS,
  [ConversationTabsValues.ASSIGNED_TO_ME]: (args) => ASSIGNED_TO_ME_FILTERS(args),
  [ConversationTabsValues.RESOLVED]: () => RESOLVED_FILTERS
};

export enum ChatInputDisabledReason {
  CONVERSATION_RESOLVED = "CONVERSATION_RESOLVED",
  CONVERSATION_IS_ASSIGNED_TO_OTHER_PERSON = "CONVERSATION_IS_ASSIGNED_TO_OTHER_PERSON",
  CONVERSATION_IS_ASSIGNED_TO_AI_BOT = "CONVERSATION_IS_ASSIGNED_TO_AI_BOT",
  NONE = "NONE"
}

export const AI_BOT_ACTOR_GROUP_NAME = "AI_BOT";

export const generateDefaulMessagingTableURLSearchParams = () =>
  generateURLSearchParams(INBOX_FILTERS);
