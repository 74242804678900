import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box, FormHelperText, Grid2, IconButton, Paper, TextField, useTheme } from "@mui/material";
import {
  CommunicationChannel,
  Paragraph,
  Select,
  TenantContext
} from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { AttachFile, Send } from "@mui/icons-material";
import { useReplyMessageMutation } from "../../graphql-messaging/mutations/ReplayMessage.generated";
import { ConversationStatus, MessageType } from "../../graphql-messaging/generated/graphql";
import { AutocompleteComponent, AutocompleteOption } from "../../components/autocomplete-component";
import { AssigneeActor } from "../domain/conversation";
import { useUpdateConversationMutationEnhanced } from "../../graphql-messaging/mutations/enhanced-mutations/update-conversation-enhanced";
import { useIsMobile } from "../../hooks/use-is-mobile";
import { useEmployee } from "../../hooks/use-employee";
import { ChatInputDisabledReason } from "../domain/conversation-constants";
import { useChatUploadFile } from "../hooks/use-chat-upload-file";
import {
  clearMessagingUploadState,
  removeMessagingUpload,
  selectAllUploadedAttachments,
  selectAttachmentIsLoading,
  selectFileUploads,
  selectUploadIds,
  uploadMessageAttachment
} from "../../slices/messaging-attachment.slice";
import { useDispatch, useSelector } from "../../store";
import { unwrapResult } from "@reduxjs/toolkit";
import { generateUUID } from "../../utils/data.utils";
import { UploadItem } from "./upload/upload-item";
import { ErrorCode as UploadErrorCode } from "react-dropzone";
import { useInjectMessage } from "../hooks/use-inject-message";

const acceptedImageTypes = ["image/jpeg", "image/png"];

export const ChatInput: FC<{
  conversationId: string;
  communicationChannel?: CommunicationChannel;
  disabledReason: ChatInputDisabledReason;
  assignee?: AssigneeActor;
  propertyId: string;
  version?: string | null;
}> = ({ conversationId, communicationChannel, disabledReason, assignee, propertyId, version }) => {
  const { t } = useTranslationWrapper();
  const [text, setText] = useState("");
  const { enabledCommunicationChannels } = useContext(TenantContext);
  const isMobile = useIsMobile();
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const theme = useTheme();
  const disabled = useMemo(() => disabledReason !== ChatInputDisabledReason.NONE, [disabledReason]);

  const channelOptions = useMemo(
    () =>
      enabledCommunicationChannels.map((value) => ({
        value: value,
        label: t(`labels__guest__communication__channel__${value}`)
      })),
    [t, enabledCommunicationChannels]
  );
  const [uploadError, setUploadError] = useState<UploadErrorCode | string | null>(null);
  const uploadErrorMapper: Record<UploadErrorCode, string> = {
    "file-invalid-type": t("errors__file_invalid_type"),
    "file-too-large": t("errors__too_large"),
    "file-too-small": t("errors__too_small"),
    "too-many-files": t("errors__too_many_files")
  };
  const { currentEmployee, employees } = useEmployee([propertyId]);
  const [sendMessageAction] = useReplyMessageMutation();
  const [updateConversation] = useUpdateConversationMutationEnhanced();
  const allAttachments = useSelector(selectAllUploadedAttachments);
  const attachmentLoading = useSelector(selectAttachmentIsLoading);
  const attachmentIds = useSelector(selectUploadIds);
  const fileUploads = useSelector(selectFileUploads);

  const { injectMessage } = useInjectMessage();

  useEffect(
    () => () => {
      dispatch(clearMessagingUploadState());
      setUploadError(null);
    },
    [dispatch]
  );
  const sendMessage = useCallback(() => {
    if (!assignee) {
      updateConversation({
        conversation: {
          conversationId,
          status: ConversationStatus.InProgress,
          assignee: {
            actorId: currentEmployee?.id
          }
        }
      });
    }

    sendMessageAction({
      message: {
        type: MessageType.ConversationMessage,
        content: {
          text,
          attachmentIds
        },
        conversationId,
        pmsPropertyId: propertyId ?? undefined
      }
    }).then(() => {
      dispatch(injectMessage({ conversationId }));
      dispatch(clearMessagingUploadState());
      setText("");
    });
  }, [
    dispatch,
    conversationId,
    propertyId,
    text,
    sendMessageAction,
    assignee,
    currentEmployee?.id,
    updateConversation,
    injectMessage,
    attachmentIds
  ]);

  const sendMessageV2 = useCallback(() => {
    if (!assignee) {
      updateConversation({
        conversation: {
          conversationId,
          status: ConversationStatus.InProgress,
          assignee: {
            actorId: currentEmployee?.id
          }
        }
      });
    }
    sendMessageAction({
      message: {
        type: MessageType.ConversationMessage,
        content: {
          text: text,
          attachments: fileUploads.map((fileUpload) => ({
            fileName: fileUpload.fileName ?? "",
            mediaType: fileUpload.mediaType ?? "",
            mediaUrl: fileUpload.mediaUrl ?? ""
          }))
        },
        conversationId,
        pmsPropertyId: propertyId ?? undefined
      }
    }).then(() => {
      dispatch(injectMessage({ conversationId }));
      dispatch(clearMessagingUploadState());
      setText("");
    });
  }, [
    dispatch,
    conversationId,
    propertyId,
    text,
    sendMessageAction,
    assignee,
    currentEmployee?.id,
    updateConversation,
    injectMessage,
    fileUploads
  ]);

  const updateAssignee = useCallback(
    async (item: AutocompleteOption) => {
      await updateConversation({
        conversation: {
          conversationId,
          status: ConversationStatus.InProgress,
          assignee: {
            actorId: item.id
          }
        }
      });
    },
    [conversationId, updateConversation]
  );

  const options = useMemo(() => {
    const listOfEmployee = (employees || []).map((item) => ({
      id: `${item.id}`,
      label: [item.firstName, item.lastName].join(" ")
    }));

    return !assignee
      ? listOfEmployee.concat({
          id: "",
          label: t("labels__not_assigned")
        })
      : listOfEmployee;
  }, [employees, assignee, t]);

  const { getRootProps, open, isDragActive, getInputProps } = useChatUploadFile({
    async onDropAccepted(acceptedFiles: Array<File>): Promise<void> {
      setUploadError(null);
      await Promise.all(
        acceptedFiles.map((file) => {
          return dispatch(
            uploadMessageAttachment({
              file,
              localIdentifier: generateUUID(),
              preview: acceptedImageTypes.includes(file["type"]) ? URL.createObjectURL(file) : ""
            })
          ).then(unwrapResult);
        }) ?? []
      );
    },
    onDropRejected: (fileRejections) => {
      setUploadError(fileRejections[0].errors[0].code);
    },
    allowZipUpload: communicationChannel === CommunicationChannel.EMAIL
  });
  const { onClick, ...restOfRootProps } = getRootProps();
  const removeFile = useCallback(
    (fileIdentifier: string) => {
      dispatch(removeMessagingUpload(fileIdentifier));
    },
    [dispatch]
  );
  return (
    <Paper
      elevation={0}
      sx={{
        mt: 1,
        height: "100%",
        borderBottomRightRadius: isMobile ? 0 : 8,
        borderBottomLeftRadius: isMobile ? 0 : 8
      }}
    >
      <Grid2
        container
        spacing={1}
        sx={{
          justifyContent: "space-between",
          px: 2,
          py: 1,
          height: "100%"
        }}
      >
        <Grid2 size={{ xs: 12, md: 4 }}>
          <Select
            options={channelOptions}
            value={communicationChannel ?? enabledCommunicationChannels[0]}
            label={t("labels__notification__modal__channel")}
            id="channel.value"
            name="channel.value"
            variant="outlined"
            disabled
          />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 8 }}>
          <Grid2
            container
            spacing={1}
            sx={{
              justifyContent: isMobile ? "space-between" : "right",
              alignItems: "center"
            }}
          >
            <Grid2 size={{ xs: 8, md: 6 }}>
              <AutocompleteComponent
                options={options as AutocompleteOption[]}
                onSelected={(inputValue) => {
                  updateAssignee(inputValue);
                }}
                value={{
                  id: assignee?.actorId ?? "",
                  label: assignee?.displayName ?? ""
                }}
                label={t("labels__task_assignee")}
              />
            </Grid2>
          </Grid2>
        </Grid2>
        {disabled ? (
          <Grid2 size={{ xs: 12 }}>
            <Paragraph sx={{ background: palette.background.default }} p={1}>
              {t(`labels__disabled_reason_${disabledReason}`)}
            </Paragraph>
          </Grid2>
        ) : (
          <Grid2 size={{ xs: 12 }}>
            <Grid2
              container
              sx={{
                alignItems: "center"
              }}
            >
              <Grid2 size={{ xs: 9, md: 10 }}>
                <section {...restOfRootProps}>
                  <input hidden {...getInputProps()} />
                  {isDragActive ? (
                    <Box
                      sx={{
                        height: isMobile ? 70 : 100,
                        border: "1px dashed",
                        borderRadius: `${theme.shape.borderRadius}px`
                      }}
                    >
                      <Paragraph textAlign="center" pt={isMobile ? 2.5 : 4}>
                        {t("labels__drop_file_label")}
                      </Paragraph>
                    </Box>
                  ) : (
                    <TextField
                      placeholder={t("labels__send_message_placeholder")}
                      value={text}
                      onChange={(e) => {
                        setText(e.target.value);
                      }}
                      multiline
                      sx={{ width: "99%" }}
                      rows={isMobile ? 1 : 2}
                      disabled={disabled}
                      slotProps={{
                        input: {
                          sx: {
                            alignItems: "baseline",
                            flexDirection: "column",
                            pt: 1,
                            pb: 0,
                            height: 75
                          },
                          endAdornment: allAttachments.length ? (
                            <Box
                              sx={{
                                overflow: "auto",
                                overflowY: "hidden",
                                alignItems: "center",
                                display: "flex",
                                width: "100%",
                                whiteSpace: "nowrap"
                              }}
                            >
                              <Grid2 container direction="row" wrap="nowrap">
                                {allAttachments.map((item) => (
                                  <Grid2
                                    key={item.localIdentifier}
                                    sx={{
                                      mr: 1
                                    }}
                                  >
                                    <UploadItem uploadItem={item} removeFile={removeFile} />
                                  </Grid2>
                                ))}
                              </Grid2>
                            </Box>
                          ) : undefined
                        }
                      }}
                    />
                  )}
                </section>
                {uploadError && (
                  <FormHelperText error required>
                    {t(uploadErrorMapper[uploadError as UploadErrorCode]) ?? uploadError}
                  </FormHelperText>
                )}
              </Grid2>

              <Grid2
                size={{ xs: 3, md: 2 }}
                sx={{
                  textAlign: "right"
                }}
              >
                <Grid2
                  container
                  direction="row"
                  sx={{
                    justifyContent: "end"
                  }}
                >
                  <IconButton
                    disabled={disabled}
                    sx={{
                      color: disabled ? "disabled" : palette.accent.main
                    }}
                    onClick={open}
                    onTouchEnd={open}
                  >
                    <AttachFile />
                  </IconButton>
                  <IconButton
                    sx={{
                      color: disabled ? "disabled" : palette.accent.main
                    }}
                    onClick={version === "2" ? sendMessageV2 : sendMessage}
                    disabled={attachmentLoading || disabled}
                  >
                    <Send />
                  </IconButton>
                </Grid2>
              </Grid2>
            </Grid2>
          </Grid2>
        )}
      </Grid2>
    </Paper>
  );
};
