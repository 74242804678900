import * as Types from "../generated/graphql";

import { ActorFragmentDoc } from "../fragments/Actor.generated";
import { ActorGroupFragmentDoc } from "../fragments/ActorGroup.generated";
import { MessageFragmentDoc } from "../fragments/Message.generated";
import { messagingApi } from "../messaging.api";
export type GetConversationDetailsQueryVariables = Types.Exact<{
  filter: Types.ConversationFilter;
}>;

export type GetConversationDetailsQuery = {
  __typename?: "Query";
  GetConversations: {
    __typename?: "ConversationsPage";
    totalElements: number;
    content: Array<{
      __typename?: "Conversation";
      conversationId: string;
      lastReceivedMessage?: any | null;
      messageBirdVersion?: string | null;
      pmsPropertyId: string;
      status: Types.ConversationStatus;
      numberOfUnreadMessages: number;
      replyToPlatform?: Types.Platform | null;
      assignee?: {
        __typename?: "Actor";
        actorId?: string | null;
        actorGroupId?: string | null;
        displayName?: string | null;
        actorGroup?: {
          __typename?: "ActorGroup";
          actorGroupType: Types.ActorGroupType;
          name: string;
        } | null;
      } | null;
      guest: {
        __typename?: "Actor";
        actorId?: string | null;
        actorGroupId?: string | null;
        displayName?: string | null;
        actorGroup?: {
          __typename?: "ActorGroup";
          actorGroupType: Types.ActorGroupType;
          name: string;
        } | null;
      };
      guestIdentity?: {
        __typename?: "GuestIdentity";
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        gender?: Types.Gender | null;
        pmsId?: string | null;
        lastConversations: Array<{
          __typename?: "Conversation";
          conversationId: string;
          status: Types.ConversationStatus;
          assignee?: {
            __typename?: "Actor";
            actorId?: string | null;
            actorGroupId?: string | null;
            displayName?: string | null;
            actorGroup?: {
              __typename?: "ActorGroup";
              actorGroupType: Types.ActorGroupType;
              name: string;
            } | null;
          } | null;
          messages: Array<{
            __typename?: "Message";
            updatedAt: any;
            conversationId: string;
            messageBirdVersion?: string | null;
            messageId: string;
            content: any;
            direction: Types.Direction;
            read: boolean;
            createdAt: any;
            status: Types.MessageStatus;
            to?: {
              __typename?: "Actor";
              actorId?: string | null;
              actorGroupId?: string | null;
              displayName?: string | null;
              actorGroup?: {
                __typename?: "ActorGroup";
                actorGroupType: Types.ActorGroupType;
                name: string;
              } | null;
            } | null;
            from?: {
              __typename?: "Actor";
              actorId?: string | null;
              actorGroupId?: string | null;
              displayName?: string | null;
              actorGroup?: {
                __typename?: "ActorGroup";
                actorGroupType: Types.ActorGroupType;
                name: string;
              } | null;
            } | null;
            channel: { __typename?: "Channel"; platform: Types.Platform; name?: string | null };
          } | null>;
        }>;
        reservations?: Array<{
          __typename?: "Reservation";
          arrival: any;
          departure: any;
          pmsId: string;
          pmsPropertyId: string;
          magicId: string;
          status: Types.ReservationStatus;
          confirmationNumber?: string | null;
          services?: Array<{
            __typename?: "Service";
            name?: string | null;
            magicServiceCodeEnum?: string | null;
            quantity: number;
            price?: {
              __typename?: "GrossPrice";
              currency?: string | null;
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
            } | null;
          } | null> | null;
        } | null> | null;
      } | null;
      messages: Array<{
        __typename?: "Message";
        conversationId: string;
        messageBirdVersion?: string | null;
        messageId: string;
        content: any;
        direction: Types.Direction;
        read: boolean;
        createdAt: any;
        status: Types.MessageStatus;
        to?: {
          __typename?: "Actor";
          actorId?: string | null;
          actorGroupId?: string | null;
          displayName?: string | null;
          actorGroup?: {
            __typename?: "ActorGroup";
            actorGroupType: Types.ActorGroupType;
            name: string;
          } | null;
        } | null;
        from?: {
          __typename?: "Actor";
          actorId?: string | null;
          actorGroupId?: string | null;
          displayName?: string | null;
          actorGroup?: {
            __typename?: "ActorGroup";
            actorGroupType: Types.ActorGroupType;
            name: string;
          } | null;
        } | null;
        channel: { __typename?: "Channel"; platform: Types.Platform; name?: string | null };
      } | null>;
    }>;
  };
};

export const GetConversationDetailsDocument = `
    query GetConversationDetails($filter: ConversationFilter!) {
  GetConversations(filter: $filter) {
    totalElements
    content {
      conversationId
      lastReceivedMessage
      messageBirdVersion
      pmsPropertyId
      assignee {
        ...Actor
      }
      guest {
        ...Actor
      }
      guestIdentity {
        email
        firstName
        id
        lastName
        phone
        gender
        pmsId
        lastConversations: conversations(page: {pageNumber: 0, pageSize: 3}) {
          conversationId
          status
          assignee {
            ...Actor
          }
          messages(
            filter: {pageable: {pageNumber: 0, pageSize: 1, sort: "createdAt,desc"}}
          ) {
            updatedAt
            ...Message
          }
        }
        reservations {
          arrival
          departure
          pmsId
          pmsPropertyId
          magicId
          status
          confirmationNumber
          services {
            name
            magicServiceCodeEnum
            quantity
            price {
              currency
              grossPrice
              grossPriceInCents
            }
          }
        }
      }
      status
      numberOfUnreadMessages
      replyToPlatform
      messages(
        filter: {pageable: {pageNumber: 0, pageSize: 1000, sort: "createdAt,asc"}}
      ) {
        ...Message
      }
    }
  }
}
    ${ActorFragmentDoc}
${ActorGroupFragmentDoc}
${MessageFragmentDoc}`;

const injectedRtkApi = messagingApi.injectEndpoints({
  endpoints: (build) => ({
    GetConversationDetails: build.query<
      GetConversationDetailsQuery,
      GetConversationDetailsQueryVariables
    >({
      query: (variables) => ({ document: GetConversationDetailsDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useGetConversationDetailsQuery, useLazyGetConversationDetailsQuery } =
  injectedRtkApi;
