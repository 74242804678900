import { ParagraphBold, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button } from "@mui/material";
import { FC } from "react";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import AddIcon from "@mui/icons-material/Add";
import { TaskPreview } from "src/features/tasks/task-preview";
import { Task } from "src/features/tasks/domain/task";
import { useTaskModal } from "src/features/tasks/use-task-select-id";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TitleHeader } from "src/features/reservations/reservation-details/reservation-details-tabs/overview/card-header/title-header";

interface ChatPreviewTasksProps {
  tasks: Task[];
  primaryReservationId: string;
}

export const ChatPreviewTasks: FC<ChatPreviewTasksProps> = ({ tasks, primaryReservationId }) => {
  const { t } = useTranslationWrapper();
  const { openNewTaskWithParam, openNewTask } = useTaskModal({});
  return (
    <Accordion sx={{ boxShadow: "none" }} disableGutters defaultExpanded>
      <AccordionSummary
        sx={{ px: 0 }}
        expandIcon={tasks.length > 0 ? <ExpandMoreIcon /> : <></>}
        aria-controls="last-tasks"
        id="last-tasks"
      >
        <ParagraphBold sx={{ display: "flex", alignItems: "center" }}>
          <TitleHeader title={t("title__tasks")} length={tasks?.length} />
        </ParagraphBold>
        <Box
          sx={{
            px: 2
          }}
        >
          <Button
            variant="ghost"
            size="large"
            onClick={() =>
              primaryReservationId
                ? openNewTaskWithParam(primaryReservationId, "RESERVATION")
                : openNewTask()
            }
            startIcon={<AddIcon fontSize="small" />}
          >
            <ParagraphSmall>{t("buttons__create_task")}</ParagraphSmall>
          </Button>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ px: 0 }}>
        {tasks.map((task) => (
          <Box key={task.id}>
            <TaskPreview task={task} />
          </Box>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};
