import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Box, Grid2, useTheme } from "@mui/material";
import { Button, Heading2, useGlobalModal } from "@likemagic-tech/sv-magic-library";
import { ChatItem } from "./chat-item";
import { ChatInput } from "./chat-input";
import { ConversationDetails } from "../domain/conversation";
import { transformCommunicationPlatformV2 } from "../../graphql/transform/transform-utils";
import { ConversationStatus } from "../../graphql-messaging/generated/graphql";
import { useEmployee } from "../../hooks/use-employee";
import { ChatInputDisabledReason } from "../domain/conversation-constants";
import { useIsMobile } from "src/hooks/use-is-mobile";
import { api as setConversationAsReadApi } from "../../graphql-messaging/mutations/SetConversationAsRead.generated";
import { useDispatch } from "../../store";
import { getYesNoModalArg } from "../../utils/modal-util";
import { useConversationSelectId } from "../use-conversation-select-id";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { useUpdateConversationMutationEnhanced } from "../../graphql-messaging/mutations/enhanced-mutations/update-conversation-enhanced";
import CreateIcon from "@mui/icons-material/Create";
import { EditPropertyModal } from "./edit-property-modal";
import { useProperty } from "../../hooks/use-property";
import { ChatScrollToBottom } from "../../components/chat-scroll-to-bottom/chat-scroll-to-bottom";
import { useMessageSelectId } from "./use-message-select-id";

interface ChatProps {
  conversation: ConversationDetails;
  propertyIds: string[];
}

export const ChatInputHeight = 240;
export const ChatHeaderHeight = 90;
export const Chat: FC<ChatProps> = ({ conversation }) => {
  const { messages, conversationId, status, assignee, guestIdentity } = conversation;
  const dispatch = useDispatch();
  const { open: openModal } = useGlobalModal();
  const { closeDrawer } = useConversationSelectId();
  const { t } = useTranslationWrapper();
  const theme = useTheme();
  const [updateConversation] = useUpdateConversationMutationEnhanced();
  const platformOfLastMessage = useMemo(() => conversation.replyToPlatform, [conversation]);
  const [isChangePropertyModalOpen, setIsChangePropertyModalOpen] = useState(false);

  const { getProperty } = useProperty();

  const propertyId = useMemo(
    () =>
      conversation.pmsPropertyId === "ACCOUNT"
        ? ""
        : getProperty(conversation.pmsPropertyId)?.propertyId ?? "",
    [getProperty, conversation.pmsPropertyId]
  );

  const { currentEmployee } = useEmployee([propertyId]);

  const isMobile = useIsMobile();
  const { selectedMessageId } = useMessageSelectId();

  useEffect(() => {
    if (
      conversation.conversationId &&
      currentEmployee?.actorId &&
      currentEmployee?.actorId.toString() === conversation.assignee?.actorId
    ) {
      dispatch(
        setConversationAsReadApi.endpoints.SetConversationAsRead.initiate({
          conversationId: conversation.conversationId
        })
      );
    }
  }, [
    dispatch,
    conversation.conversationId,
    currentEmployee?.actorId,
    conversation.assignee?.actorId
  ]);

  console.log(currentEmployee);
  console.log(assignee);
  const disabledReason = useMemo(() => {
    if (status === ConversationStatus.Resolved) {
      return ChatInputDisabledReason.CONVERSATION_RESOLVED;
    } else if (currentEmployee?.actorId?.toString() !== assignee?.actorId && !!assignee?.actorId) {
      return ChatInputDisabledReason.CONVERSATION_IS_ASSIGNED_TO_OTHER_PERSON;
    } else {
      return ChatInputDisabledReason.NONE;
    }
  }, [status, assignee?.actorId, currentEmployee?.actorId]);

  const resolveDisabled = useMemo(
    () => disabledReason !== ChatInputDisabledReason.NONE,
    [disabledReason]
  );

  const resolveAction = useCallback(() => {
    updateConversation({
      conversation: {
        conversationId,
        status: ConversationStatus.Resolved
      }
    });
    closeDrawer();
  }, [conversationId, updateConversation, closeDrawer]);

  const onResolveClick = useCallback(async () => {
    const result = await openModal(
      getYesNoModalArg(t("labels__are_you_sure"), "", t("labels__yes"), t("labels__no"))
    );
    if (result) {
      resolveAction();
    }
  }, [resolveAction, openModal, t]);

  return (
    <Grid2
      container
      sx={{
        pt: isMobile ? 2.5 : 3
      }}
    >
      <Grid2
        container
        spacing={1}
        direction={isMobile ? "column-reverse" : "row"}
        sx={{
          height: ChatHeaderHeight,
          px: isMobile ? 1.5 : 3,
          width: "100%",
          flexWrap: "nowrap"
        }}
      >
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Heading2>{conversation.guest.displayName}</Heading2>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Grid2
            container
            spacing={1}
            direction={isMobile ? "row-reverse" : "row"}
            sx={{
              justifyContent: isMobile ? "flex-end" : "space-between"
            }}
          >
            <Grid2 size={{ xs: 7, md: 9 }}>
              <Button
                variant="secondary"
                onClick={() => {
                  setIsChangePropertyModalOpen(true);
                }}
                sx={{ gap: 1 }}
              >
                <Box
                  sx={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    display: "block",
                    maxWidth: theme.spacing(16)
                  }}
                >
                  {conversation.pmsPropertyId === "ACCOUNT"
                    ? t("labels__no_property")
                    : getProperty(conversation.pmsPropertyId)?.details?.name}
                </Box>
                <CreateIcon sx={{ color: theme.palette.grey[600] }} />
              </Button>
            </Grid2>
            <Grid2
              size={{ xs: 3 }}
              sx={{ display: "flex", justifyContent: isMobile ? "flex-start" : "flex-end" }}
            >
              <Button variant="primary" onClick={onResolveClick} disabled={resolveDisabled}>
                {t("labels__conversation_status_to_resolve")}
              </Button>
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2
        size={{ xs: 12 }}
        sx={{
          pr: !isMobile ? 3 : 1,
          pl: !isMobile ? 3 : 1,
          overflowY: "scroll",

          height: `calc(100vh - ${ChatInputHeight}px - 64px - ${ChatHeaderHeight}px - ${
            isMobile ? "12px" : "24px"
          })`
        }}
      >
        <Box
          sx={{
            pb: 2
          }}
        >
          {messages?.map((message) => (
            <ChatItem
              key={message?.messageId}
              message={message}
              selectedMessageId={selectedMessageId}
              guestIdentity={guestIdentity}
            />
          ))}
          <ChatScrollToBottom selectedMessageId={selectedMessageId} messages={messages} />
        </Box>
      </Grid2>
      <Grid2
        sx={{
          pb: !isMobile ? 3 : 0,
          pr: !isMobile ? 3 : 0,
          pl: !isMobile ? 3 : 0,
          pt: !isMobile ? 1 : 0,
          height: ChatInputHeight,
          width: "100%"
        }}
      >
        <ChatInput
          version={conversation.messageBirdVersion}
          conversationId={conversationId}
          communicationChannel={transformCommunicationPlatformV2(platformOfLastMessage)}
          disabledReason={disabledReason}
          assignee={assignee}
          propertyId={propertyId}
        />
      </Grid2>
      <EditPropertyModal
        isOpen={isChangePropertyModalOpen}
        onClose={() => setIsChangePropertyModalOpen(false)}
        conversation={conversation}
      />
    </Grid2>
  );
};
